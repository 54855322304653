import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
export const ASSET_NAME = 'landing-page';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { createImageVariantConfig } from '../../util/sdkLoader';

const QUERY_LISTINGS_LIMIT = 8;

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/LandingPage/SET_INITIAL_STATE';

export const QUERY_LISTINGS_REQUEST = 'app/LandingPage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/LandingPage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/LandingPage/QUERY_LISTINGS_ERROR';

const initialState = {
  queryListingsInProgress: false,
  queryListingsError: null,
  listingRefs: { service: [], event: [] },
};

export default function landingPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,
        listingRefs: state.listingRefs,
        queryListingsInProgress: true,
        queryListingsError: null,
      };
    case QUERY_LISTINGS_SUCCESS:
      return {
        ...state,
        queryListingsInProgress: false,
        listingRefs: { ...state.listingRefs, [payload.type]: payload.listingRefs },
      };
    case QUERY_LISTINGS_ERROR:
      return {
        ...state,
        queryListingsInProgress: false,
        listingRefs: { service: [], event: [] },
        queryListingsError: payload,
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const queryListingsRequest = () => ({
  type: QUERY_LISTINGS_REQUEST,
});

export const queryListingsSuccess = (type, listingRefs) => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { type: type, listingRefs },
});

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const queryListings = (type, config) => (dispatch, getState, sdk) => {
  const listingType =
    type === 'service'
      ? { pub_listingType: 'service-nightly,service-hourly' }
      : type === 'product'
      ? { pub_listingType: 'free-digital-download,paid-digital-download' }
      : type === 'podcast'
      ? { pub_listingType: 'podcast' }
      : type === 'zenscape'
      ? { pub_listingType: 'zenscapes' }
      : { pub_listingType: 'event' };

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;

  const aspectRatio = aspectHeight / aspectWidth;

  dispatch(queryListingsRequest());
  return sdk.listings
    .query({
      ...listingType,
      include: ['author', 'author.profileImage', 'images'],
      'fields.image': [
        'variants.scaled-small',
        'variants.scaled-medium',
        `variants.${variantPrefix}`,
        `variants.${variantPrefix}-2x`,

        // Avatars
        'variants.square-small',
        'variants.square-small2x',
      ],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      'limit.images': 1,
      limit: QUERY_LISTINGS_LIMIT,
    })
    .then(response => {
      const listingRefs = response.data.data.map(l => l.id);
      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingFields };
      dispatch(addMarketplaceEntities(response, sanitizeConfig));
      dispatch(queryListingsSuccess(type, listingRefs));
      return response;
    })
    .catch(e => {
      return dispatch(queryListingsError(storableError(e)));
    });
};

export const loadData = (params, search, config) => dispatch => {
  const pageAsset = { landingPage: `content/pages/${ASSET_NAME}.json` };
  return Promise.all([
    dispatch(fetchPageAssets(pageAsset, true)),
    dispatch(queryListings('service', config)),
    dispatch(queryListings('event', config)),
    dispatch(queryListings('product', config)),
    dispatch(queryListings('podcast', config)),
    dispatch(queryListings('zenscape', config)),
  ]);
};
